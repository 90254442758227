import axios from "axios"
import { Modal } from "react-bootstrap"
import { getSessionData } from "../../../utils/storageUtils"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import ClickableTable from "../../../components/ClickableTable/ClickableTabl"
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface"

interface ModalHistoricoChatsProps {
    show: boolean
    fecharModal: () => void
}

const ModalHistoricoChats: React.FC<ModalHistoricoChatsProps> = ({
    show,
    fecharModal
}) => {

    const [dados, setDados] = useState<any[]>([])

    const idUsuario = getSessionData('DadosUsuarioID') || ""
    const token = getSessionData('MultfilialWebToken') || "";

    const navigate = useNavigate()

    async function buscaHistoricoUsuario() {
        try {
            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscaHistorico/${idUsuario}`, {
                headers: {
                    Authorization: token
                }
            })

            setDados(resposta.data.data)
        } catch (error: any) {
            if (error.response?.status === 403 || error.response?.status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login");
            } else {
                toast.error(error.response?.data.message)
            }
        }
    }

    const colunas: ITabela[] = [
        { titulo: "Protocolo", acesso: "id_conversa" },
        { titulo: "Cliente", acesso: "fantasia" },
        { titulo: "Criação", acesso: "data_criacao" },
        { titulo: "Finalização", acesso: "data_finalizacao" }
    ];

    return (
        <>
            <Modal show={show} centered size="lg" onShow={buscaHistoricoUsuario}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{"Histórico de Chat"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem', cursor: 'pointer' }}
                        onClick={fecharModal}
                    ></i>
                </Modal.Header>

                <Modal.Body className="bg-white">
                    <ClickableTable
                        data={dados}
                        itemsPerPage={10}
                        coluna={colunas}
                        onRowClick={() => { }}
                        usaAcoes={false}
                        acaoEditar={() => { }}
                        acaoExcluir={() => { }}
                        acaoVisualizar={() => { }}
                    />
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ModalHistoricoChats