import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { getSessionData } from "../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import ModalConfirmacao from "../../components/ModalConfirmacao/ModalConfirmacao";

interface Mensagem {
    id: string;
    nome: string;
    mensagem: string;
    tipoUsuario: string;
}

function ChatConversa() {
    const [mensagens, setMensagens] = useState<Mensagem[]>([]);
    const [disabledMessages, setDisabledMessages] = useState<boolean>(false)
    const [novaMensagem, setNovaMensagem] = useState<string>("")
    const [socket, setSocket] = useState<Socket | null>(null);
    const [idConversa, setIdConversa] = useState<string>(''); // Armazenar o ID da conversa
    const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false)
    const [conversaEmAndamento, setConversaEmAndamento] = useState<boolean>(false)
    const [suporte, setSuporte] = useState<string>('-')
    const [cliente, setCliente] = useState<string>('')
    const [status, setStatus] = useState<string>('-')
    const [nomeUsuarioCliente, setNomeUsuarioCliente] = useState<string>('-')

    const novaMensagemRef = useRef<HTMLInputElement>(null)

    const params = useParams()
    const navigate = useNavigate()

    const token = getSessionData('MultfilialWebToken') || "";
    const idFilial = getSessionData('DadosIdfilial') || ""
    const idUsuario = getSessionData('DadosUsuarioID') || ""
    const nomeUsuario = getSessionData('NomeUsuario') || ""

    const tipoUsuario = params.tipoUsuario
    const idCliente = params.idCliente

    async function iniciarChatSuporte(idConversa: string) {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/iniciarChat`, {
            idConversa,
            idUsuario,
            idFilial,
            idCliente
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success("Chat iniciado, aguarde um agente conectar a conversa")
            setCliente(resposta.data.cliente || "---")
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                setMensagens([])
            }
        })
    }

    async function encerrarChatSuporte() {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/encerrarChat`, {
            idConversa,
            idFilial
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else if (status !== 409) {
                toast.error(erro.response.data.message)
            }
        })
    }

    async function buscaInfosSuporte() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscaInfos/suporte/${idCliente}/${params.idConversa}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setNomeUsuarioCliente(resposta.data.nomeUsuario)
            setCliente(resposta.data.cliente)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else if (status !== 409) {
                toast.error(erro.response.data.message)
            }
        })
    }

    const adicionarMensagem = (novaMensagem: Mensagem) => {
        setMensagens((prevMensagens) => {
            // Verifica se a mensagem com o mesmo conteúdo e tipo de usuário já existe
            const existeMensagem = prevMensagens.some(
                (msg) =>
                    msg.mensagem === novaMensagem.mensagem &&
                    msg.tipoUsuario === novaMensagem.tipoUsuario
            );

            // Adiciona a nova mensagem apenas se ela não existir
            if (!existeMensagem) {
                return [...prevMensagens, novaMensagem];
            }

            // Caso exista, mantém o histórico intacto
            return prevMensagens;
        });
    };

    function enviarMensagem() {
        if (novaMensagem.trim() !== "" && socket) {
            // Enviar a mensagem para o servidor
            socket.emit(
                `${tipoUsuario === 'Cliente' ? 'mensagemCliente' : 'mensagemSuporte'}`,
                idConversa,
                nomeUsuario,
                novaMensagem
            );

            const mensagemObj: Mensagem = {
                id: "eu",
                nome: nomeUsuario,
                mensagem: novaMensagem,
                tipoUsuario: tipoUsuario || '',
            };

            adicionarMensagem(mensagemObj)
            setNovaMensagem("")

            // Adicionar a mensagem ao estado local (mensagens do cliente)
            // setMensagens((prevMensagens) => [
            //     ...prevMensagens,
            //     { id: "eu", nome: nomeUsuario, mensagem: novaMensagem, tipoUsuario: tipoUsuario } // Inclua o nome do usuário
            // ]);
            // setNovaMensagem(""); // Limpar o campo de texto
        }
    };

    function inicializarSocket(idConversa?: string) {
        const newSocket = io("http://localhost:8080"); // Conecta ao servidor
        setSocket(newSocket);

        // Listener para mensagens do suporte
        const handleMensagem = (data: any) => {
            // setMensagens((prevMensagens) => [...prevMensagens, data]);
            adicionarMensagem(data)
        };

        if (tipoUsuario === 'Suporte') {

            // Caso exista um ID de conversa, entra na conversa
            newSocket.emit("entrarChat", idConversa, nomeUsuario);

            buscaInfosSuporte()
            setSuporte(nomeUsuario)

            // console.log(`Conectado ao chat existente com ID: ${idConversa}`);
            newSocket.on("mensagemCliente", handleMensagem);

        } else if (!idConversa) {

            newSocket.emit("iniciarConversa");
            newSocket.on("mensagemSuporte", handleMensagem);

            setNomeUsuarioCliente(nomeUsuario)

            // Ouvir o evento de resposta com o ID da nova conversa
            newSocket.on("conversaIniciada", (id: string) => {
                setIdConversa(id);
                // setIdDaConversa(id)

                iniciarChatSuporte(id);
            });
        }
        setConversaEmAndamento(true)
        setStatus("Chat em andamento")

        //Listener para quando suporte entrar na sala
        newSocket.on("suporteEntrou", (data) => {
            setSuporte(data.nomeSuporte)
            setDisabledMessages(false)
            if (tipoUsuario === 'Cliente') {
                toast.info("Agente conectado a conversa")
            }
            novaMensagemRef.current?.focus()
        })

        // Listener para erros de conversa
        newSocket.on("erroConversa", (data: any) => {
            toast.error(data.mensagem);
            setMensagens([])
        });

        // Listener para encerramento do chat
        newSocket.on("chatEncerrado", (data: any) => {
            if (tipoUsuario !== data.tipoUsuarioComando) {
                toast.info(data.mensagem);
            }
            setStatus("Chat finalizado")
            setConversaEmAndamento(false)
            setMensagens([])
            setDisabledMessages(true)
            // setIdDaConversa(null)
            handleClose();
        });

        // Cleanup quando o modal fechar
        return () => {
            // Remove os listeners com base no tipo de usuário
            if (tipoUsuario === "Cliente") {
                newSocket.off("mensagemSuporte");
            } else {
                newSocket.off("mensagemCliente");
            }
            newSocket.off("erroConversa");
        };
    }

    const handleClose = () => {
        if (socket) {
            socket.emit("encerrarChat", idConversa, tipoUsuario);
            socket.disconnect(); // Desconecta ao fechar
            setSocket(null);

            // Remove os listeners com base no tipo de usuário
            if (tipoUsuario === "Cliente") {
                socket.off("mensagemSuporte");
            } else {
                socket.off("mensagemCliente");
            }

            setMostraModalConfirm(false)
            setConversaEmAndamento(false)
            setMensagens([])
            setStatus("Chat finalizado")
            encerrarChatSuporte()
        }
        // onClose();
    };

    useEffect(() => {
        if (tipoUsuario === 'Cliente') {
            setDisabledMessages(true)
        } else {
            setDisabledMessages(false)
        }

        if (!conversaEmAndamento) {
            if (params.idConversa) {
                inicializarSocket(params.idConversa)
                setIdConversa(params.idConversa)
            } else if (!conversaEmAndamento) {
                inicializarSocket()
            }

        }
    }, [])

    return (
        <>
            <ContainerTitulo titulo={`Chat`} />

            <Card className="shadow-lg rounded">
                <Card.Body>
                    <Row>

                        <Col md={8}>
                            <Card className="shadow-sm rounded">
                                <Card.Header className="bg-light" style={{ padding: "0rem 1rem" }}>
                                    <Row className="align-items-center">
                                        <Col>
                                            <h5 className="m-0">Chat com {tipoUsuario}</h5>
                                        </Col>
                                        <Col className="text-end">
                                            <button
                                                className="btn btn-link text-secondary"
                                                style={{ fontSize: "1.5rem" }}
                                                onClick={() => {
                                                    setMostraModalConfirm(true)
                                                }}
                                            >
                                                <i className="bi bi-x"></i>
                                            </button>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <div style={{ height: "300px", overflowY: "auto", padding: "10px", background: "#f8f9fa", borderRadius: "5px" }}>
                                        {mensagens.map((msg, index) => (
                                            <div
                                                key={index}
                                                className={`mb-2 ${msg.id === "eu" ? "text-end" : "text-start"}`}
                                            >
                                                <div className="small text-muted">
                                                    {msg.id === "eu" ? "Você" : msg.nome || "Usuário"}
                                                </div>

                                                <span
                                                    className={`badge px-3 py-2 ${msg.id === "eu" ? "bg-primary text-light" : "bg-secondary text-light"}`}
                                                >
                                                    {msg.mensagem}
                                                </span>
                                            </div>
                                        ))}
                                    </div>

                                    <Row className="w-100 mt-3">
                                        <Col md={9}>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control rounded-pill shadow-sm"
                                                    disabled={disabledMessages}
                                                    value={novaMensagem}
                                                    ref={novaMensagemRef}
                                                    autoFocus
                                                    onChange={(e) => setNovaMensagem(e.target.value)}
                                                    onKeyDown={(e) => e.key === "Enter" && enviarMensagem()}
                                                    placeholder="Digite sua mensagem..."
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <button
                                                className="btn btn-primary rounded-pill w-100 shadow-sm"
                                                disabled={disabledMessages}
                                                onClick={enviarMensagem}
                                            >
                                                Enviar
                                            </button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="shadow-sm rounded">
                                <Card.Header className="bg-light">
                                    <h5 className="m-0">Informações do Chat</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md={12}>
                                            <strong>Protocolo da conversa:</strong> {idConversa}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <strong>Nome do usuário:</strong> {nomeUsuarioCliente}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <strong>Nome do suporte:</strong> {suporte}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <strong>Cliente:</strong> {cliente}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <strong className="me-2">Status:</strong> <span className={`badge ${conversaEmAndamento ? 'bg-primary' : 'bg-danger'}`}>{status}</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <ModalConfirmacao
                mostrar={mostraModalConfirm}
                msgAcao1="Cancelar"
                msgAcao2="Confirmar"
                msgPrincipal="Deseja encerrar o chat com suporte?"
                Acao1={() => {
                    setMostraModalConfirm(false)
                }}
                Acao2={handleClose}
            // backgroundColor="bg-dark"
            />
        </>
    )
}

export default ChatConversa