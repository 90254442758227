import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/img/principal.jpg";
import { getSessionData } from "../utils/storageUtils";
import ApiGet from "../api/endPoints/useGet";
import { useEffect, useState } from "react";
import { ModalTrocarSenhaLink } from "./administrativo/usuario/components/ModalTrocarSenhaLink";
import { setSessionData } from "../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import ModalSelecionarFilialLink from "./cadastro/filiais/filiais/components/ModalSelecionaClinicaLink/ModalSelecionaClinicaLink";
import ModalLoading from "../components/Formularios/Modal/ModalLoading";
import armazem from "../assets/img/armazem.png";
import avatar from "../assets/img/avatar.png";

const LeftBar = (): JSX.Element => {
  const isSuper = getSessionData("isPerfilSuper") || "";
  const id_perfil = getSessionData("id_perfil")
  const token = getSessionData("MultfilialWebToken") || "";
  const id_filial = getSessionData("DadosIdfilial")
  const navigate = useNavigate()

  const isVinculo = getSessionData("isPerfilVinculo") || "0";
  const usuario = getSessionData("NomeUsuario") || "";
  const perfil = getSessionData("PerfilUsuario") || "";
  const id_usuario = getSessionData("DadosUsuarioID") || "";
  const login_usuario = getSessionData("DadosUsuario") || "";
  const avatarfilial = getSessionData("DadosAvatarfilial") || "";

  const [abrir, setAbrir] = useState<boolean>(false);
  const [notificacoes, setNotificacoes] = useState<number>(0)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [chatAberto, setChatAberto] = useState<boolean>(false)

  const [linkManual, set_LinkManuel] = useState("")

  const params = useParams()
  // function CarregarLinks() {
  //   axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/LINK`, {
  //     headers: {
  //       Authorization: token
  //     }
  //   }).then(function (resposta) {
  //     console.log('chegou')
  //     set_LinkManuel(resposta.data[0].defusr01)
  //   }).catch(function (erro) {
  //     console.log(erro.response.data.message)
  //     toast.error(erro)
  //   })
  // }

  // useEffect(() => {
  //   CarregarLinks()
  //   if (usuario === "") {
  //     navigate("/login");
  //   }
  // }, []);

  const logoff = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const [avatarImg, setAvatarImg] = useState<string>(avatarfilial || "");
  const carregarAvatar = async (id_usuario: string) => {
    const id_filial = getSessionData('DadosIdfilial')

    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: `/usuario/carregar/:idusuario/${id_filial}`,
      parametros: {
        idusuario: id_usuario,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {
        const { avatar } = response[0];
        setLoading(false);
        setAvatarImg(avatar);
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    if (id_usuario !== "") carregarAvatar(id_usuario);
  }, [id_usuario]);

  //#endregion

  //#region Carregar Filiais do usuario
  const [filiais, setFiliais] = useState<any[]>([]);
  const [avatarImgFilial, setAvatarImgFilial] = useState<any>("");
  const [idfilial, setIdfilial] = useState<any>("");
  const [nomefilial, setNomefilial] = useState<any>("");

  const carregarFiliais = async (id_usuario: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/filial/usuario/listar/:id_usuario/:id_empresa",
      parametros: {
        id_usuario: id_usuario,
        id_empresa: isVinculo,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {

        const retornoApi = response;
        const qtdfilial = retornoApi.length;
        setLoading(false);
        if (qtdfilial == 1) {
          setFiliais(response);
          setAvatarImgFilial(response[0].avatar);
          setSessionData("imgFilial", response[0].avatar)
          setIdfilial(response[0].id_filial);
          setNomefilial(response[0].fantasia);
          setSessionData("DadosIdfilial", response[0].id_filial);
          setSessionData("DadosNomefilial", response[0].fantasia);
          setSessionData("DadosAvatarfilial", response[0].avatar);
          setSessionData("DadosIdfilial", response[0].id_filial);
          setSessionData("DadosNomefilial", response[0].fantasia);
        }

        const id_filial = getSessionData("DadosIdfilial")
        if (qtdfilial > 1 && id_filial == null) {
          setFiliais(response);

          //setSessionData("DadosIdfilial", response[0].id_filial);
          //setSessionData("DadosNomefilial", response[0].fantasia);
          // setAbrir(true);
        }
        else if (qtdfilial != 0) {

          setFiliais(response)
          setIdfilial(getSessionData("DadosIdfilial"))
          setNomefilial(getSessionData("DadosNomefilial")!.replace(/"/g, ''))
          setAvatarImgFilial(getSessionData("imgFilial")!.replace(/"/g, ''))
        }
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };

  useEffect(() => {
    if (id_usuario !== "") carregarFiliais(id_usuario);

    if (params.idFilial) {
      setIsDisabled(true)
      setChatAberto(true)
    } else {
      setIsDisabled(false)
      setChatAberto(false)
    }
  }, [id_usuario]);

  //#endregion

  const fecharModal = () => {
    sessionStorage.clear();
    navigate("/login");
    // setAbrir(false)
  };

  const receberDados = (dados: any) => {
    setAvatarImgFilial(dados.avatar);
    setSessionData("imgFilial", dados.avatar)
    setSessionData("isPerfilVinculo", dados.id_empresa)
    setIdfilial(dados.id_filial);
    setNomefilial(dados.fantasia);
    setSessionData("DadosAvatarfilial", dados.avatar);
    setSessionData("DadosIdfilial", dados.id_filial);
    setSessionData("DadosNomefilial", dados.fantasia);
    navigate("/");
    setAbrir(false);
  };



  const [loading, setLoading] = useState<boolean>(false);

  //controle de permissões --- inicio
  const [acessoADM, setacessoADM] = useState(true)
  const [empresaHOLDING, setempresaHOLDING] = useState(true)
  const [empresaPERFILPERMISSAO, setempresaPERFILPERMISSAO] = useState(true)
  const [empresaUSUARIO, setempresaUSUARIO] = useState(true)
  const [empresaCODSISTEMA, setempresaCODSISTEMA] = useState(true)
  const [empresaFORMULARIOS, setempresaFORMULARIOS] = useState(true)

  const [acessoCADASTRO, setacessoCADASTRO] = useState(true)
  const [acessoFILIAL, setacessoFILIAL] = useState(true)
  const [acessoBOT, setacessoBOT] = useState(true)
  const [acessoCadClientes, setAcessoCadClientes] = useState(true)

  const [acessoTICKET, setacessoTICKET] = useState(true)
  const [visualizarTICKET, setVisualizarTICKET] = useState(true)
  const [incluirTICKET, setIncluirTICKET] = useState(true)
  const [acessoTotalTICKET, setacessoTotalTICKET] = useState(true)

  const [visualizarRelatorios, setVisualizarRelatorios] = useState(true)

  const [acessoControleTICKET, setacessoControleTICKET] = useState(true)
  const [visualizarControleTICKET, setVisualizarControleTICKET] = useState(true)
  const [editarControleTICKET, setEditarControleTICKET] = useState(true)
  const [acessoTotalControleTICKET, setacessoTotalControleTICKET] = useState(true)

  const [acessoControleMetas, setAcessoControleMetas] = useState(true)

  const [acessoConfiguracaoAgentes, setAcessoConfiguracaoAgentes] = useState(true)

  const [acessoGestaoAtendimentos, setAcessoGestaoAtendimentos] = useState(true)
  const [visualizarAtendimentos, setVisualizarAtendimentos] = useState(true)
  const [visualizarRelatorioSatisfacao, setVisualizarRelatorioSatisfacao] = useState(true)

  const [acessoChatSuporte, setAcessoChatSuporte] = useState(true)

  async function CarregarPermissões(id_perfil: any, id_filial: any) {
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:idperfil/:idfilial",
      parametros: {
        idperfil: id_perfil,
        idfilial: id_filial
      },
      headers: headers,
    };
    ApiGet(dados)
      .then(function (retorno) {
        //##ACESSO ADM - módulo 
        if (retorno[0].visualizar_empresa_holding === 1 ||
          retorno[0].incluir_empresa_holding === 1 ||
          retorno[0].editar_empresa_holding === 1 ||
          retorno[0].excluir_empresa_holding === 1 ||
          retorno[0].visualizar_perfil_permissao === 1 ||
          retorno[0].incluir_perfil_permissao === 1 ||
          retorno[0].editar_perfil_permissao === 1 ||
          retorno[0].excluir_perfil_permissao === 1 ||
          retorno[0].visualizar_usuario === 1 ||
          retorno[0].incluir_usuario === 1 ||
          retorno[0].editar_usuario === 1 ||
          retorno[0].excluir_usuario === 1 ||
          retorno[0].visualizar_codsistema === 1 ||
          retorno[0].incluir_codsistema === 1 ||
          retorno[0].editar_codsistema === 1 ||
          retorno[0].excluir_codsistema === 1 ||
          retorno[0].visualizar_formularios === 1 ||
          retorno[0].incluir_formularios === 1 ||
          retorno[0].editar_formularios === 1 ||
          retorno[0].excluir_formularios === 1) {

          setacessoADM(false) //tem acesso à adm - mostrar adm

          //##ACESSO empresa(holding) - submódulo 
          if (retorno[0].visualizar_empresa_holding === 1 ||
            retorno[0].incluir_empresa_holding === 1 ||
            retorno[0].editar_empresa_holding === 1 ||
            retorno[0].excluir_empresa_holding === 1) {
            setempresaHOLDING(false) //tem acesso empresa(holding) - submódulo 
          }
          //##ACESSO Perfil / Permissão - submódulo 
          if (retorno[0].visualizar_perfil_permissao === 1 ||
            retorno[0].incluir_perfil_permissao === 1 ||
            retorno[0].editar_perfil_permissao === 1 ||
            retorno[0].excluir_perfil_permissao === 1) {
            setempresaPERFILPERMISSAO(false) //tem acesso empresa(holding) - submódulo 
          }
          //##ACESSO usuario - submódulo 
          if (retorno[0].visualizar_usuario === 1 ||
            retorno[0].incluir_usuario === 1 ||
            retorno[0].editar_usuario === 1 ||
            retorno[0].excluir_usuario === 1) {
            setempresaUSUARIO(false) //tem acesso empresa(holding) - submódulo 
          }
          //##ACESSO usuario - submódulo 
          if (retorno[0].visualizar_codsistema === 1 ||
            retorno[0].incluir_codsistema === 1 ||
            retorno[0].editar_codsistema === 1 ||
            retorno[0].excluir_codsistema === 1) {
            setempresaCODSISTEMA(false) //tem acesso empresa(holding) - submódulo 
          }

          //##ACESSO formulários - submódulo 
          if (retorno[0].visualizarFormularios === 1 ||
            retorno[0].incluirFormularios === 1 ||
            retorno[0].editarFormularios === 1 ||
            retorno[0].excluirFormularios === 1) {
            setempresaFORMULARIOS(false) //tem acesso formulários - submódulo 
          }

        }
        else {
          setacessoADM(true) //não tem acesso - não mostrar
        }

        //##ACESSO CADASTRO - módulo 
        if (retorno[0].visualizarfilial === 1 ||
          retorno[0].incluirfilial === 1 ||
          retorno[0].editarfilial === 1 ||
          retorno[0].excluirfilial === 1 ||
          retorno[0].visualizarBot === 1 ||
          retorno[0].incluirBot === 1 ||
          retorno[0].editarBot === 1 ||
          retorno[0].excluirBot === 1 ||
          retorno[0].visualizarCadClientes === 1 ||
          retorno[0].incluirCadClientes === 1 ||
          retorno[0].editarCadClientes === 1 ||
          retorno[0].excluirCadClientes === 1) {

          setacessoCADASTRO(false) //tem acesso à cadastro - mostrar cadastro

          if (retorno[0].visualizarfilial === 1 ||
            retorno[0].incluirfilial === 1 ||
            retorno[0].editarfilial === 1 ||
            retorno[0].excluirfilial === 1) {
            setacessoFILIAL(false)//tem acesso à filial - mostrar filial

          }
          if (retorno[0].visualizarBot === 1 ||
            retorno[0].incluirBot === 1 ||
            retorno[0].editarBot === 1 ||
            retorno[0].excluirBot === 1) {
            setacessoBOT(false)//tem acesso à filial - mostrar filial

          }
          if (retorno[0].visualizarCadClientes === 1 ||
            retorno[0].incluirCadClientes === 1 ||
            retorno[0].editarCadClientes === 1 ||
            retorno[0].excluirCadClientes === 1) {
            setAcessoCadClientes(false)//tem acesso à filial - mostrar filial

          }
        }
        else {
          setacessoCADASTRO(true) //não tem acesso - não mostrar
        }

        //##ACESSOS TICKET
        if (retorno[0].visualizarTicket === 1 &&
          retorno[0].incluirTicket === 1) {
          setacessoTICKET(false)
          setIncluirTICKET(false)
          setVisualizarTICKET(false)
        } else if (retorno[0].visualizarTicket === 1) {
          setacessoTICKET(false)
          setVisualizarTICKET(false)
        } else {
          setacessoTICKET(true)
        }

        //##ACESSOS CONTROLE TICKET
        if (retorno[0].visualizarControleTicket === 1 &&
          retorno[0].editarControleTicket === 1) {
          setacessoControleTICKET(false)
          setEditarControleTICKET(false)
          setVisualizarControleTICKET(false)
        } else if (retorno[0].visualizarControleTicket === 1) {
          setacessoControleTICKET(false)
          setVisualizarControleTICKET(false)
        } else if (retorno[0].editarControleTicket === 1) {
          setacessoControleTICKET(false)
          setEditarControleTICKET(false)
        } else {
          setacessoControleTICKET(true)
        }

        //#ACESSO RELATÓRIOS DE TICKETS
        if (retorno[0].visualizarRelatorios == 1) {
          setVisualizarRelatorios(false)
        } else {
          setVisualizarRelatorios(true)
        }

        //##ACESSOS CONFIGURACAO DE AGENTES
        if (retorno[0].visualizarConfiguracaoAgentes === 1 ||
          retorno[0].editarConfiguracaoAgentes === 1 ||
          retorno[0].incluirConfiguracaoAgentes === 1 ||
          retorno[0].excluirConfiguracaoAgentes === 1) {
          setAcessoConfiguracaoAgentes(false)
        } else {
          setAcessoConfiguracaoAgentes(true)
        }

        //##ACESSOS CONTROLE DE METAS
        if (retorno[0].visualizarMetas === 1 ||
          retorno[0].incluirMetas === 1 ||
          retorno[0].editarMetas === 1 ||
          retorno[0].excluirMetas === 1) {
          setAcessoControleMetas(false)
        } else {
          setAcessoControleMetas(true)
        }

        //#ACESSO GESTAO DE ATENDIMENTOS
        if (
          retorno[0].visualizarGestaoAtendimentos == 1 &&
          retorno[0].visualizarRelatorioSatisfacao == 1
        ) {
          setAcessoGestaoAtendimentos(false)
        } else {
          setAcessoGestaoAtendimentos(true)
        }

        //#ACESSO GESTAO DE ATENDIMENTOS -> ATENDIMENTOS
        if (retorno[0].visualizarGestaoAtendimentos == 1) {
          setVisualizarAtendimentos(false)
        } else {
          setVisualizarAtendimentos(true)
        }

        //#ACESSO GESTÃO DE ATENDIMENTOS -> RELATÓRIO DE SATISFAÇÃO
        if (retorno[0].visualizarRelatorioSatisfacao == 1) {
          setVisualizarRelatorioSatisfacao(false)
        } else {
          setVisualizarRelatorioSatisfacao(true)
        }


      }).catch(function (erro) {
        sessionStorage.clear();
        navigate("/login");
      })
  }


  //#endRegion controle de permissões -- FIM

  useEffect(function () {
    CarregarPermissões(id_perfil, id_filial)
  }, [id_filial])

  function verificaMensagensChats() {
    if (chatAberto || isDisabled) {
      return
    }

    const idFilialPreenchido = idfilial || getSessionData("DadosIdfilial")

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregaChats/suporte/${id_perfil}/${idFilialPreenchido}/${id_usuario}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {
      setNotificacoes(resposta.data.data.length)
      const audio = new Audio("/notification.mp3");
      audio.play();
    }).catch(function (erro) {
      const status = erro.response.status;
      setNotificacoes(0)
      if (status === 403 || status === 401) {
        toast.error("Acesso negado, faça seu login novamente");
        navigate("/login")
      }
    })
  };

  useEffect(() => {
    const intervalId = setInterval(verificaMensagensChats, 60000);

    verificaMensagensChats();

    return () => clearInterval(intervalId);
  }, []);


  return (
    <>
      <div className={`leftside-menu ${isDisabled ? "disabled-menu" : ""}`}>
        <a href="/" className="logo text-center ">
          <span className="logo-lg">
            <img src={Logo} alt="" style={{ height: "70px" }} />
          </span>
          <span className="logo-sm">
            <img src={Logo} alt="" style={{ height: "35px" }} />
          </span>
        </a>

        <div className="h-100" id="leftside-menu-container" data-simplebar>
          <ul className="side-nav">
            <li className="side-nav-item" hidden={acessoADM}>
              <a
                data-bs-toggle="collapse"
                href="#Administrativo"
                aria-expanded="false"
                aria-controls="Administrativo"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="clarity:administrator-solid"
                  data-width="22"
                ></span>
                <span style={{ margin: "10px" }}>Administrativo</span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="Administrativo">
                <ul className="side-nav-second-level">
                  {isSuper === "1" && (
                    <li hidden={empresaHOLDING}>
                      <Link to={"/administrativo/empresa"}>
                        Empresa (Holding)
                      </Link>
                    </li>
                  )}
                  {isSuper === "1" && (
                    <li hidden={empresaPERFILPERMISSAO}>
                      <Link to={"/administrativo/permisao"}>
                        Perfil / Permissão
                      </Link>
                    </li>
                  )}
                  <li hidden={empresaUSUARIO}>
                    <Link to={"/administrativo/usuario"}>Usuário</Link>
                  </li>

                  {isSuper === "1" && (
                    <li hidden={empresaCODSISTEMA}>
                      <Link to={"/administrativo/codistema"}>
                        Código do Sistema
                      </Link>
                    </li>
                  )}

                  {isSuper === "1" && (
                    <li hidden={empresaFORMULARIOS}>
                      <Link to={"administrativo/formularios/formularios"}>Formulários</Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
            <li className="side-nav-item" hidden={acessoCADASTRO}>
              <a
                data-bs-toggle="collapse"
                href="#Cadastro"
                aria-expanded="false"
                aria-controls="Cadastro"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="fluent:people-list-24-filled"
                  data-width="22"
                ></span>
                <span style={{ margin: "10px" }}>Cadastro</span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="Cadastro">
                <ul className="side-nav-third-level">
                  <li hidden={acessoFILIAL}>
                    <Link to={"cadastro/filiais/filial"}>Filial</Link>
                  </li>
                  <li hidden={acessoBOT} >
                    <Link hidden={acessoFILIAL} to={"cadastro/bots/filial/listagem"}>Bot</Link>
                  </li>
                  <li hidden={acessoCadClientes} >
                    <Link hidden={acessoCadClientes} to={"cadastro/clientes/clientes"}>Clientes</Link>
                  </li>
                  <li>
                    <Link hidden={acessoControleMetas} to={"controleMetas/administrarMetas"}>Metas/SLA</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="side-nav-item" hidden={acessoTICKET}>
              <a
                data-bs-toggle="collapse"
                href="#Tickets"
                aria-expanded="false"
                aria-controls="Tickets"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="fluent:archive-16-filled"
                  data-width="22"
                ></span>
                <span style={{ margin: "10px" }}>Tickets</span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="Tickets">
                <ul className="side-nav-second-level">
                  <li>
                    <Link hidden={visualizarTICKET} to={"tickets/visualizacao/"}>Visualizar Tickets</Link>
                  </li>
                  <li>
                    <Link hidden={incluirTICKET} to={"tickets/criacao/"}>Criar Ticket</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="side-nav-item" hidden={acessoControleTICKET}>
              <a
                data-bs-toggle="collapse"
                href="#ControleTickets"
                aria-expanded="false"
                aria-controls="Tickets"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="fluent:archive-16-filled"
                  data-width="22"
                ></span>
                <span style={{ margin: "10px" }}>Controle de Tickets</span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="ControleTickets">
                <ul className="side-nav-second-level">
                  <li>
                    <Link hidden={visualizarControleTICKET} to={"tickets/controleTickets"}>Administrar Tickets</Link>
                  </li>
                  <li>
                    <Link hidden={visualizarRelatorios} to={"tickets/controleTickets/relatorios"}>Relatórios</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="side-nav-item" hidden={acessoConfiguracaoAgentes}>
              <a
                data-bs-toggle="collapse"
                href="#ConfiguracaoAgentess"
                aria-expanded="false"
                aria-controls="ConfiguracaoAgentess"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="mdi:face-agent"
                  data-width="22"
                ></span>
                <span style={{ margin: "10px", fontSize: "13px" }}>Configuração de Agentes</span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="ConfiguracaoAgentess">
                <ul className="side-nav-second-level">
                  <li>
                    <Link hidden={acessoConfiguracaoAgentes} to={"configuracaoAgentes/agentes/"}>Agentes</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="side-nav-item" hidden={acessoGestaoAtendimentos}>
              <a
                data-bs-toggle="collapse"
                href="#GestaoAtendimentos"
                aria-expanded="false"
                aria-controls="GestaoAtendimentos"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="fluent-mdl2:b-i-dashboard"
                  data-width="22"
                ></span>
                <span style={{ margin: "10px", fontSize: "13px" }}>Gestão de Atendimentos</span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="GestaoAtendimentos">
                <ul className="side-nav-second-level">
                  <li>
                    <Link hidden={visualizarAtendimentos} to={"gestaoAtendimentos/gestaoAtendimentos"}>Atendimentos</Link>
                  </li>
                  <li>
                    <Link hidden={visualizarRelatorioSatisfacao} to={"gestaoAtendimentos/relatorioSatisfacao"}>Relatório de Satisfação</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="side-nav-item" hidden={acessoControleTICKET}>
              <a
                data-bs-toggle="collapse"
                href="#ChatSuporte"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/chat/suporte")
                }}
                aria-expanded="false"
                aria-controls="ControleTickets"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="fluent:chat-help-24-filled"
                  data-width="22"
                ></span>
                <span style={{ margin: "10px" }}>Chat Suporte</span>
                <span
                  className="badge"
                  style={{
                    position: "absolute",
                    top: "-1px",
                    right: "90px",
                    backgroundColor: "red",
                    color: "white",
                    fontSize: "10px",
                    borderRadius: "50%",
                    padding: "3px 6px",
                  }}
                  hidden={notificacoes === 0 ? true : false}
                >
                  {notificacoes}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>



      {/*<div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-white">
        <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <div className="col-sm-0 col-md-0 col-lg-0">
            <a href="/">
              <img className="logoMain w-100" src={Logo} alt="" />
            </a>
          </div>
          <br />
          <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
            <li className="w-100" hidden={acessoADM}>
              <a href="#submenu3" data-bs-toggle="collapse" className="nav-link px-0 align-middle text-dark">
                <i className="bi bi-person-lines-fill" style={{ fontSize: "larger" }}></i> <span className="ms-1 d-none d-sm-inline pt-2">Administrativo</span>
              </a>
              <ul className="collapse nav flex-column ms-4" id="submenu3" data-bs-parent="#menu">
                {isSuper === "1" && (
                  <li hidden={empresaHOLDING}>
                    <a className="nav-link px-0 text-dark" href={"/administrativo/empresa"}>
                      Empresa (Holding)
                    </a>
                  </li>
                )}
                {isSuper === "1" && (
                  <li hidden={empresaPERFILPERMISSAO}>
                    <a className="nav-link px-0 text-dark" href={"/administrativo/permisao"}>
                      Perfil / Permissão
                    </a>
                  </li>
                )}
                <li hidden={empresaUSUARIO}>
                  <a className="nav-link px-0 text-dark" href={"/administrativo/usuario"}>Usuário</a>
                </li>
                {isSuper === "1" && (
                  <li hidden={empresaCODSISTEMA}>
                    <a className="nav-link px-0 text-dark" href={"/administrativo/codistema"}>
                      Código do Sistema
                    </a>
                  </li>
                )}
              </ul>
            </li>
            <li className="w-100" hidden={acessoCADASTRO}>
              <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle text-dark">
                <i className="bi bi-person-plus-fill" style={{ fontSize: "larger" }}></i> <span className="ms-1 d-none d-sm-inline pt-2">Cadastros</span>
              </a>
              <ul className="collapse nav flex-column ms-4" id="submenu2" data-bs-parent="#menu">
                <li>
                  <a className="nav-link px-0 text-dark" hidden={acessoFILIAL} href={"cadastro/filiais/filial"}>Filial</a>
                </li>
                <li>
                  <a href={"cadastro/bots/filial/listagem"} hidden={acessoBOT} className="nav-link px-0 align-middle text-dark">Bot</a>
                </li>
              </ul>
            </li>
          </ul>
          <br />
          <div className="dropdown pb-4 w-100">
            <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">

              <img src={avatarImg ? `data:image/png;base64,${avatarImg}` : avatar} alt="hugenerd" width="20" height="20" className="rounded-circle" />
              <p className="d-none d-sm-inline mx-1 text-dark mt-1">{usuario} - {perfil}</p>
            </a>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow w-100">
              <li><a className="dropdown-item text-white" href={linkManual} target="_blank">Manual do Usuario</a></li>
              <ModalTrocarSenhaLink />
              <li><a className="dropdown-item text-white" href={`/administrativo/usuario/usuario/editar/${id_usuario}`}>Minha Conta</a></li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li><a className="dropdown-item text-white" href="/login">Sair</a></li>
            </ul>
          </div>
          <div className="dropdown pb-4 w-100">
            <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">

              <img src={`data:image/png;base64,${avatarImgFilial}`} alt="hugenerd" width="30" height="30" className="rounded-circle" />
              <p className="d-none d-sm-inline mx-1 text-dark mt-1">{idfilial} - {nomefilial}</p>
            </a>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">

              {filiais.map(function (item) {

                return (
                  <>
                    <li onClick={function () {
                      setAvatarImgFilial(item.avatar);
                      setSessionData("imgFilial", item.avatar)
                      setIdfilial(item.id_filial);
                      setNomefilial(item.fantasia);
                      setSessionData("DadosAvatarfilial", item.avatar);
                      setSessionData("DadosIdfilial", item.id_filial);
                      setSessionData("DadosNomefilial", item.fantasia);

                      if (getSessionData("isPerfilSuper") == "1") {
                        setSessionData("isPerfilVinculo", item.id_empresa)
                      }

                      navigate("/");
                    }}><a className="dropdown-item text-white">
                        <img src={`data:image/png;base64,${item.avatar}`} alt="img" width="20" height="20" className="rounded-circle" />
                        <span className="ms-1">{item.id_filial} - {item.fantasia}</span></a></li>
                  </>
                )
              })}
            </ul>
          </div>
        </div>
            </div>*/}

      <ModalLoading show={loading} label="Carregando dados" />

      <ModalSelecionarFilialLink
        abrir={abrir}
        onClose={fecharModal}
        onclick={receberDados}
      />
    </>
  );
};

export { LeftBar };
